export default function() {
  const FloatLabel = (() => {
    // add active class
    const handleFocus = e => {
      const { target } = e;
      target.parentNode.classList.add('active');
    };

    // remove active class
    const handleBlur = e => {
      const { target } = e;
      if (!target.value && !target.getAttribute('placeholder')) {
        target.parentNode.classList.remove('active');
      }
    };
    // register events
    const bindEvents = element => {
      const floatField = element.querySelector('input, textarea');
      floatField.addEventListener('focus', handleFocus);
      floatField.addEventListener('blur', handleBlur);
      floatField.addEventListener('change', handleFocus);
      floatField.addEventListener('input', handleFocus);
    };
    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll('.floating-label');
      if (floatContainers.length > 0) {
        [...floatContainers].forEach(element => {
          if (
            element.querySelectorAll(
              'input:not([type="checkbox"]), textarea, input:not([type="radio"])',
            ).length > 0
          ) {
            if (
              element.querySelector(
                'input:not([type="checkbox"]), textarea, input:not([type="radio"])',
              ).value ||
              element.querySelector('input, textarea').getAttribute('placeholder')
            ) {
              element.classList.add('active');
            }
          }
          if (element.querySelectorAll('select').length === 0) {
            bindEvents(element);
          }
        });
      }
    };
    return {
      init,
    };
  })();

  FloatLabel.init();
}
